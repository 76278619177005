import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Chip,
    colors,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    makeStyles,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTextField } from '../Textfield';
import Modal from './Modal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomDatePicker, CustomTimePicker } from '../DateTimePicker';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import '../DateTimePicker/react-datepicker.css';
import Swal from 'sweetalert2';

export const FILTER_INPUT_TYPE = {
    TEXT: 'TEXT',
    MULTISELECT: 'MULTISELECT',
    SELECT: 'SELECT',
    SWITCH: 'SWITCH',
    DATE: 'DATE',
    DATERANGE: 'DATERANGE',
    RADIOGROUP: 'RADIOGROUP',
    TIMERANGE: 'TIMERANGE',
    DATE_RANGE_START_END: 'DATE_RANGE_START_END',
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipRoot: {
        marginRight: 5,
    },
}));

export function FilteredState({
    filterOptions,
    filteredValues = {},
    onDelete,
    selectInfo,
    count,
    selected,
    ...props
}) {
    const classes = useStyles();

    // const shouldRender =
    //     !!Object?.keys(filteredValues)?.length &&
    //     !!Object?.values(filteredValues)?.filter((i) => !!i?.length)?.length;
    const maxShowSlice =
        Object?.keys(filteredValues)?.length > props?.maxShow
            ? props?.maxShow
            : !!Object?.keys(filteredValues)?.length
            ? Object?.keys(filteredValues)?.length
            : 0;
    console.log('theee maxShow', maxShowSlice);
    let DoNotRender = ['startDate', 'endDate'];
    let renderItems = Object?.keys(filteredValues)?.filter(
        (key) => !DoNotRender?.includes(key),
    );
    return (
        !!true && (
            <Box
                className={`${classes.root}`}
                display={'flex'}
                alignItems={'center'}
                style={{
                    margin: '0px 16px 0px 24px',
                }}
            >
                <Box>
                    {renderItems?.map((itemKey, index) => {
                        let values = [];

                        if (
                            typeof filteredValues?.[itemKey] == 'string' ||
                            typeof filteredValues?.[itemKey] == 'number'
                        ) {
                            values = filteredValues?.[itemKey];
                        } else if (Array.isArray(filteredValues?.[itemKey])) {
                            values = filteredValues?.[itemKey]
                                ?.map((i) => i?.label)
                                ?.join(',');
                        }

                        let selectedItem = filterOptions?.find(
                            (i) => i?.name === itemKey,
                        );
                        let labelText =
                            !!values && !!selectedItem?.label
                                ? `${selectedItem?.label ?? ''}: ${
                                      values ?? ''
                                  }`
                                : '';

                        if (!!selectedItem?.render) {
                            labelText = selectedItem?.render();
                        }
                        if (DoNotRender?.includes(itemKey)) return null;
                        if (!!props?.maxShow && index == props?.maxShow) {
                            return `+${
                                renderItems?.length - props?.maxShow
                            } more`;
                        }
                        if (!!props?.maxShow && index > props?.maxShow) {
                            return null;
                        } else {
                            return (
                                !!labelText?.length && (
                                    <Chip
                                        label={labelText}
                                        variant="outlined"
                                        color="primary"
                                        className={classes.chipRoot}
                                        onDelete={
                                            !selectedItem?.deleteDisable
                                                ? () => {
                                                      onDelete &&
                                                          onDelete(itemKey);
                                                  }
                                                : null
                                        }
                                        style={{
                                            backgroundColor: '#f5f6ff',
                                        }}
                                    />
                                )
                            );
                        }
                    })}
                </Box>
                {selectInfo && (
                    <Box ml={'auto !important'}>
                        <Typography>
                            {`${selected.length} selected of ${count} `}
                            {'(Max Limit: 50)'}
                        </Typography>
                    </Box>
                )}
            </Box>
        )
    );
}
const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: 'rgba(251, 2, 2, 1)',
        '&:hover': {
            backgroundColor: 'rgba(251, 2, 2, 1)',
        },
    },
}))(Button);
export default function FilterModal({
    filterOptions,
    filteredValues,
    onFilterApplied,
    disabled,
    onClose,
    onRemoveAll,
    ...props
}) {
    const [isOpen, toggleModal] = useState(false);

    const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleClose = () => {
        toggleModal(false);
        onClose && onClose();
    };
    const handleRemoveAll = () => {
        toggleModal(false);
        onRemoveAll && onRemoveAll();
    };

    const generateFilters = (item, index) => {
        if (!item?.type) return null;
        switch (item.type) {
            case FILTER_INPUT_TYPE.TEXT:
                return (
                    <CustomTextField
                        key={index}
                        fullWidth={true}
                        id={item?.name}
                        name={item?.name}
                        label={item.label}
                        value={item?.value}
                        onChange={item?.onChange}
                        style={{ marginBottom: '16px' }}
                        isRequired={true}
                    />
                );
            case FILTER_INPUT_TYPE.MULTISELECT:
                return (
                    <Autocomplete
                        multiple={true}
                        key={index}
                        options={item?.options ?? []}
                        getOptionLabel={(option) => option?.label}
                        filterSelectedOptions={false}
                        label={item?.label}
                        value={item?.value ?? []}
                        onChange={(e, value) =>
                            item?.onChange({
                                ...e,
                                target: { ...e.target, value },
                            })
                        }
                        style={{ marginBottom: '16px' }}
                        getOptionSelected={(option) => {
                            return !!item?.value?.find(
                                (i) => i.value === option.value,
                            );
                        }}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                id={item?.name}
                                name={item?.name}
                                label={item?.label}
                                placeholder=""
                            />
                        )}
                    />
                );
            case FILTER_INPUT_TYPE.SELECT:
                return (
                    <Autocomplete
                        multiple={false}
                        key={index}
                        options={item?.options}
                        getOptionLabel={(option) => option?.label}
                        filterSelectedOptions={false}
                        label={item?.label}
                        value={item?.value}
                        onChange={(e, value) =>
                            item?.onChange({
                                ...e,
                                target: { ...e.target, value },
                            })
                        }
                        id={item?.name}
                        name={item?.name}
                        style={{ marginBottom: '16px' }}
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                id={item?.name}
                                name={item?.name}
                                label={item.label}
                                placeholder=""
                            />
                        )}
                    />
                );
            case FILTER_INPUT_TYPE.DATE:
                return (
                    <CustomDatePicker
                        label={item?.label}
                        value={item?.value}
                        onChange={item?.onChange}
                        showInput={true}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    style={{
                                        padding: 0,
                                    }}
                                >
                                    <InsertInvitationIcon size="small" />
                                </IconButton>
                            ),
                        }}
                        style={{ marginBottom: '16px' }}
                    />
                );
            case FILTER_INPUT_TYPE.DATE_RANGE_START_END:
                return (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <CustomDatePicker
                            label={item?.startDate}
                            value={item?.value?.startDate}
                            onChange={item?.onStartDateChange}
                            showInput={true}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        style={{
                                            padding: 0,
                                        }}
                                    >
                                        <InsertInvitationIcon size="small" />
                                    </IconButton>
                                ),
                            }}
                            style={{ marginBottom: '16px' }}
                        />
                        <Box m={'0px 8px'}></Box>
                        <CustomDatePicker
                            label={item?.endDate}
                            minDate={item?.value?.startDate}
                            value={item?.value?.endDate}
                            onChange={item?.onEndDateChange}
                            showInput={true}
                            disabled={!item?.value?.startDate}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        style={{
                                            padding: 0,
                                        }}
                                    >
                                        <InsertInvitationIcon size="small" />
                                    </IconButton>
                                ),
                            }}
                            style={{ marginBottom: '16px' }}
                        />
                    </Box>
                );
            case FILTER_INPUT_TYPE.DATERANGE:
                return (
                    <>
                        <CustomTextField
                            label={item.label}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {false &&
                                            item?.value?.startDate &&
                                            item?.value?.endDate && (
                                                <IconButton
                                                    className={'inputbutton'}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        item?.onChange(null);
                                                    }}
                                                >
                                                    <CloseIcon size="small" />
                                                </IconButton>
                                            )}
                                        <IconButton
                                            className={'inputbutton'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenDateRangePicker(
                                                    (prev) => !prev,
                                                );
                                            }}
                                        >
                                            <InsertInvitationIcon size="small" />
                                        </IconButton>
                                    </Box>
                                ),
                                style: {
                                    fontSize: 16,
                                    fontWeight: 600,
                                    marginBottom: 16,
                                },
                            }}
                            onClick={(e) =>
                                setOpenDateRangePicker((prev) => !prev)
                            }
                            value={
                                item?.value?.startDate && item?.value?.endDate
                                    ? `${moment(item?.value?.startDate).format(
                                          'DD MMM YY',
                                      )} to ${
                                          endDate
                                              ? moment(
                                                    item?.value?.endDate,
                                                ).format('DD MMM YY')
                                              : ''
                                      }`
                                    : ''
                            }
                            variant="outlined"
                        />
                        {openDateRangePicker && (
                            <Dialog
                                open={openDateRangePicker}
                                // TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                                disableBackdropClick
                                fullWidth={false}
                                maxWidth="xs"
                            >
                                <DialogContent
                                    style={{
                                        overflowY: 'unset',
                                        padding: '0px',
                                    }}
                                >
                                    {openDateRangePicker && (
                                        <>
                                            <DatePicker
                                                selected={startDate}
                                                isClearable
                                                onChange={(dates) => {
                                                    const [start, end] = dates;
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                    if (start && end) {
                                                        const startDate =
                                                            moment(start);
                                                        const endDate =
                                                            moment(end);

                                                        const daysDifference =
                                                            endDate.diff(
                                                                startDate,
                                                                'days',
                                                            );
                                                        /*if (
                                                            daysDifference > 365
                                                        ) {
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Date Range',
                                                                text: 'Date Range Cannot be more than 1 year',
                                                            });
                                                            return;
                                                        }*/
                                                        item?.onChange &&
                                                            item?.onChange({
                                                                startDate:
                                                                    moment(
                                                                        start,
                                                                    )
                                                                        ?.startOf(
                                                                            'day',
                                                                        )
                                                                        ?.valueOf(),
                                                                endDate: moment(
                                                                    end,
                                                                )
                                                                    ?.endOf(
                                                                        'day',
                                                                    )
                                                                    ?.valueOf(),
                                                            });
                                                        setOpenDateRangePicker(
                                                            false,
                                                        );
                                                    }
                                                }}
                                                startDate={startDate}
                                                endDate={endDate}
                                                maxDate={moment().toDate()}
                                                selectsRange
                                                inline
                                            />
                                            <Button
                                                onClick={(e) => {
                                                    setOpenDateRangePicker(
                                                        (prev) => !prev,
                                                    );
                                                }}
                                                style={{}}
                                                variant="text"
                                                disableElevation
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                </DialogContent>
                            </Dialog>
                        )}
                    </>
                );
            case FILTER_INPUT_TYPE.RADIOGROUP:
                return (
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{item?.label}</FormLabel>
                        <RadioGroup
                            row
                            aria-label="position"
                            name={item?.name}
                            value={item?.value}
                            onChange={item?.onChange}
                        >
                            {item?.options?.map((opt) => {
                                return (
                                    <FormControlLabel
                                        key={opt?.key}
                                        value={opt?.value}
                                        control={<Radio color="primary" />}
                                        label={opt?.label}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                );
            case FILTER_INPUT_TYPE.TIMERANGE:
                return (
                    <>
                        <FormLabel component="legend">{item?.label}</FormLabel>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '16px',
                            }}
                        >
                            <CustomTimePicker
                                label={'Start Time'}
                                value={item?.value}
                                onChange={() => {}}
                                id="startTimeEpochMillis"
                                name="startTimeEpochMillis"
                                style={{ marginBottom: '16px', width: '48%' }}
                            />
                            <CustomTimePicker
                                label={'End Time'}
                                value={item?.value}
                                onChange={() => {}}
                                id="endTimeEpochMillis"
                                name="endTimeEpochMillis"
                                style={{ marginBottom: '16px', width: '48%' }}
                            />
                        </Box>
                    </>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        console.log('filterOptions', filterOptions);
    }, [filterOptions]);

    const isFilterApplied =
        !!Object?.keys(filteredValues)?.length &&
        !!Object?.values(filteredValues)?.filter((i) => !!i?.length)?.length;
    return (
        <div className="filters">
            <Tooltip title="Filter" placement="top">
                <IconButton
                    // className={`inputbutton ${
                    //     isFilterApplied ? 'MuiButton-containedPrimary' : ''
                    // }`}
                    style={{
                        marginLeft: 8,
                        border: '1px solid #43419e',
                        borderRadius: 8,
                        padding: 6,
                        backgroundColor: isFilterApplied ? '#43419e' : '#fff',
                        color: !isFilterApplied ? '#43419e' : '#fff',
                    }}
                    color="primary"
                    onClick={(e) => {
                        toggleModal(true);
                    }}
                    disabled={disabled}
                >
                    <FilterListIcon size="small" />
                </IconButton>
            </Tooltip>
            {!!isOpen && (
                <Modal
                    onClose={handleClose}
                    open={isOpen}
                    title={'Filters'}
                    stickyActions={true}
                    actions={[
                        <Button
                            key={1}
                            variant="contained"
                            color="default"
                            className={`cancelButton`}
                            onClick={handleClose}
                            disableElevation
                        >
                            {'Close'}
                        </Button>,
                        ...(!!onRemoveAll
                            ? [
                                  <ColorButton
                                      key={3}
                                      variant="contained"
                                      onClick={handleRemoveAll}
                                      disabled={
                                          !Object?.keys(filteredValues)?.length
                                      }
                                  >
                                      Remove All
                                  </ColorButton>,
                              ]
                            : []),
                        <Button
                            key={2}
                            variant="contained"
                            color="primary"
                            className={`saveButton`}
                            disableElevation
                            onClick={() => {
                                onFilterApplied && onFilterApplied();
                                handleClose();
                            }}
                        >
                            {'Apply'}
                        </Button>,
                    ]}
                >
                    <Box style={{ marginTop: 16 }}>
                        {!!filterOptions?.length &&
                            filterOptions
                                ?.filter((filter) => !filter?.hide)
                                ?.map(generateFilters)}
                    </Box>
                </Modal>
            )}
        </div>
    );
}
