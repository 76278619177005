import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Divider,
    Typography,
    MenuItem,
    withStyles,
    IconButton,
    Tabs,
    Tab,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    Checkbox,
    Chip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    createStaff,
    editStaff,
    getSingleStaff,
    getHubList,
    getStaffTypeList,
    getSalaryTypeList,
    getRateCard,
    getBaseRateTypeList,
    fetchVendorList,
    fetchClientList,
} from '../../store/actions/staffActionCreator';

import {
    fetchVehicleTypes,
    getReasonMenuList,
} from '../../store/actions/attendanceActionCreator';
import Modal from './Modal';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { CustomDatePicker } from '../DateTimePicker';
import moment from 'moment';
import {
    SelfieAttendanceIcon,
    GeofenceAutoApprovalIcon,
    TrackingEnabledIcon,
    FreeLancerIcon,
} from '../../assets/svg/index';

import CustomSwitch from '../Switch';
import { CustomTextField } from '../Textfield';
import { checkHttpStatus } from '../../utils/helper';
import RateCardModal from './RateCardModal';
import PlusIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { fetchShiftfilterList } from '../../store/actions/settingsActionCreator';
import { HUB_TYPE_ENUM } from '../../utils/constants';
import { shiftOverlapValidation } from './AssignStaffToHubModal';
import AddEditStaffXUtilModal from './AddEditStaffXUtilModal';
import { useDebounce } from '../../hooks/useDebounce';
import { Vehicle_Number_Not_Mandatory } from './helper';
import { isSuperAdmin } from '../../utils/helper';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box style={{ marginTop: 20 }}>{children}</Box>}
        </div>
    );
}

function AddEditStaffModal({
    open,
    onClose,
    isEdit = false,
    data = {},
    isEnabledXUtil,
    orgId,
    dispatchCreateStaff,
    dispatchEditStaff,
    dispatchGetHubList,
    dispatchGetStaffTypeList,
    dispatchGetSalaryTypeList,
    dispatchGetSingleStaff,
    dispatchGetRateCard,
    dispatchGetBaseRateTypeList,
    dispatchGetVehicleTypeStaticValues,
    dispatchFetchVehicleTypes,
    dispatchFetchClientList,
    dispatchFetchVendorList,
    dispatchfetchShiftTimingList,
    staff,
    ...props
}) {
    const nameRegex = /^[0-9a-zA-Z\s']*$/;
    const vehicleNumberRegex = /^[A-Za-z0-9]+$/;
    const { debounce, isBouncing } = useDebounce();
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(false);
    const [state, setState] = useState({
        name: '',
        contactNumber: '',
        vehicleTypeId: null,
        vehicleNumber: '',
        hubName: '',
        selfieEnabled: true,
        // geofenceEnabled: false,
        geofenceAutoApproval: true,
        trackAndTraceEnabled: true,
        // companyId: '',
        joiningDateEpochMillis: null,
        dateOfBirthEpochMillis: null,
        staffTypeId: undefined,
        salaryType: 4,
        openingBalance: '',
    });
    const [stateStatus, setStateStatus] = useState({
        name: {},
        contactNumber: {},
        vehicleNumber: {},
    });
    const [activeTab, setActiveTab] = React.useState(0);
    const [salaryTypeList, setSalaryTypeList] = React.useState([]);
    const [salaryCycleList, setSalaryCycleList] = React.useState([
        { id: 1, name: 'Weekly', value: 'Weekly' },
        {
            id: 2,
            name: 'Bi-Weekly',
            value: 'Bi-Weekly',
        },
        {
            id: 3,
            name: 'Monthly',
            value: 'Monthly',
        },
    ]);
    const [salaryCycleDayList, setSalaryCycleDayList] = React.useState([]);
    const [rateCardResponse, setRateCardResponse] = React.useState({});
    const [baseRateTypesList, setBaseRateTypesList] = React.useState([]);
    const [filteredBaseRateTypesList, setFilteredBaseRateTypesList] =
        React.useState([]);

    const [vehicleTypesList, setVehicleTypesList] = React.useState([]);
    const [clientList, setClientList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [weekList, setWeekList] = useState([]);
    const [weekDaysList, setWeekDaysList] = useState([]);

    const [workTabHubNShiftList, setWorkTabHubNShiftList] = useState([{}]);
    const [shiftsWithHub, setShiftsWithHub] = useState({});

    const [rateCardPayload, setRateCardPayload] = React.useState({
        userId: isEdit ? data?.id : null,
        hubId: state?.hubId ?? null,
        staffCustCode: isEdit ? data?.staffCustCode : null,
    });

    const [overlapShifts, setOverlapShift] = useState([]);
    const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [trainingCompleted, setTrainingCompleted] = useState(
        data.trainingCompleted != null
            ? data.trainingCompleted.toString()
            : 'false',
    );
    const checkVehicleNumberRequired = (value) => {
        if (!vehicleTypesList) return true;
        let vehicleType = vehicleTypesList?.filter((s) => s?.id === value)?.[0];
        return (
            !!vehicleType &&
            !Vehicle_Number_Not_Mandatory.includes(vehicleType?.type)
        );
    };
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        const fetchVendor = () => {
            dispatchFetchVendorList(
                (resp) => {
                    setVendorList(resp?.response?.vendors);
                },
                (err) => {},
            );
        };
        const fetcRateCard = () => {
            dispatchGetRateCard(
                rateCardPayload,
                (resp) => {
                    // console.log('RateCardResponse', resp);
                    setRateCardResponse(resp?.response);
                },
                () => {},
            );
        };
        if (!!isEnabledXUtil) {
            if (newValue == 1) {
                fetchVendor();
                dispatchFetchClientList(
                    (resp) => {
                        setClientList(resp?.response?.customers);
                    },
                    (err) => {},
                );
            } else if (newValue == 2) {
                fetcRateCard();
            }
        } else {
            if (newValue == 1) {
                fetcRateCard();
                fetchVendor();
            }
        }
    };

    const getShiftfromHubId = async (
        hubId,
        workTabHubNShiftListIndex,
        shifts = null,
    ) => {
        await dispatchfetchShiftTimingList(
            {
                hubIds: [hubId],
            },
            async (resp) => {
                const shiftList = shifts?.map((shift) => {
                    return resp?.response?.shifts?.find(
                        (i) => i?.id === shift?.id,
                    );
                });
                await setShiftsWithHub({
                    ...shiftsWithHub,
                    [hubId]: {
                        index: workTabHubNShiftListIndex,
                        shifts: shiftList,
                        shiftList: resp?.response?.shifts,
                    },
                });
            },
            (err) => {
                // console.log('err', err);
            },
        );
    };

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const StyledMenuItem = withStyles((theme) => ({
        root: {
            margin: '0 8px',
            border: '0 !important',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: 'rgba(67, 65, 158, 0.08)',
                color: '#43419e',
                borderRadius: '8px',
            },
        },
    }))(MenuItem);

    const StyledAutocomplete = withStyles((theme) => ({
        root: {
            width: '100%',
            '& .MuiOutlinedInput-notchedOutline': {
                borderTop: 0,
                borderRadius: 0,
                borderWidth: 0,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
            },
            '& .MuiChip-root': {
                backgroundColor: '#EBEBEB',
                // border: '1px solid #e4e4e4',
            },
            '& .MuiChip-label': {
                fontWeight: 600,
            },
        },
        popper: {
            '& .MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: '#fff',
            },
        },
    }))(Autocomplete);

    const formValidator = (key, value) => {
        if (value) {
            if (key === 'name') {
                const regex = /^[0-9a-zA-Z\s']*$/;
                if (!regex.test(value)) {
                    setStateStatus((prev) => ({
                        ...prev,
                        name: {
                            status: true,
                            error: 'Invalid name',
                        },
                    }));
                } else {
                    setStateStatus((prev) => ({
                        ...prev,
                        name: {},
                    }));
                }
            } else if (key === 'contactNumber') {
                if (!value.match(/[1-9][0-9]{9}/) || value.length !== 10) {
                    setStateStatus((prev) => ({
                        ...prev,
                        contactNumber: {
                            status: true,
                            error: 'Invalid phone number',
                        },
                    }));
                } else {
                    setStateStatus((prev) => ({
                        ...prev,
                        contactNumber: {},
                    }));
                }
            } else if (key === 'vehicleNumber') {
                if (
                    !!checkVehicleNumberRequired(state?.vehicleTypeId) &&
                    (!vehicleNumberRegex.test(value) || value.includes(' '))
                ) {
                    setStateStatus((prev) => ({
                        ...prev,
                        vehicleNumber: {
                            status: true,
                            error: 'Invalid vehicle number',
                        },
                    }));
                } else {
                    setStateStatus((prev) => ({
                        ...prev,
                        vehicleNumber: {},
                    }));
                }
            }
        } else {
            if (key === 'vehicleNumber') {
                if (
                    state.trackAndTraceEnabled &&
                    state?.vehicleTypeId &&
                    !!checkVehicleNumberRequired(state?.vehicleTypeId)
                ) {
                    setStateStatus((prev) => ({
                        ...prev,
                        vehicleNumber: {
                            status: true,
                            error: 'Enter vehicle no. for Track & Trace',
                        },
                    }));
                } else {
                    setStateStatus((prev) => ({
                        ...prev,
                        vehicleNumber: {},
                    }));
                }
            }
        }
    };

    const handleClose = () => {
        // Setting form data to null before closing
        setState({
            name: '',
            contactNumber: '',
            vehicleTypeId: null,
            vehicleNumber: '',
            hubName: '',
            selfieEnabled: false,
            // geofenceEnabled: false,
            geofenceAutoApproval: false,
            trackAndTraceEnabled: false,
            staffTypeId: undefined,
            salaryType: 0,
            openingBalance: 0,
            baseRate: 0,
        });
        setStateStatus({
            name: {},
            contactNumber: {},
            vehicleNumber: {},
        });

        toggleModal(!isOpen);
        onClose && onClose();
    };

    function checkoverlapping() {
        let shiftIds = [];

        workTabHubNShiftList.forEach((hub) => {
            shiftIds = [
                ...shiftIds,
                ...(hub?.shifts?.length ? hub?.shifts : []),
            ];
        });
        const { overlapList, overlapping } = shiftOverlapValidation(shiftIds);
        setOverlapShift(overlapList);
        setSubmitButtonDisabled(overlapping);
        // isSubmitButtonDisabled = overlapping;
        // setSubmitDisabled(overlapping);
        return overlapping;
    }

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={isBouncing || isSubmitButtonDisabled}
            disableElevation
            onClick={async () => {
                debounce(async () => {
                    // alert(`${isEdit ? 'Edit Clicked' : 'Add Clicked'}`);
                    await toggleloading(true);
                    let shiftTimingIds = [];
                    workTabHubNShiftList?.forEach((hub) => {
                        if (hub?.shifts?.length)
                            shiftTimingIds = [
                                ...shiftTimingIds,
                                ...hub?.shifts?.map((s) => s?.id),
                            ];
                    });
                    if (isEdit) {
                        let payload = {
                            id: state?.id,
                            value: {
                                name: state?.name,
                                contactNumber: state?.contactNumber,
                                vehicleTypeId: state?.vehicleTypeId,
                                vehicleNumber: state?.vehicleNumber,
                                hubId: state?.hubId ?? null,
                                selfieEnabled: state?.selfieEnabled,
                                // geofenceEnabled: state?.geofenceEnabled,
                                trackAndTraceEnabled:
                                    state?.trackAndTraceEnabled,
                                geofenceAutoApproval:
                                    state?.geofenceAutoApproval,
                                // companyId: state?.companyId,
                                dateOfBirthEpochMillis: moment(
                                    state?.dateOfBirthEpochMillis,
                                ).valueOf(),
                                staffType: state?.staffTypeId,
                                salaryType: state?.salaryType,
                                salaryCycle: state?.salaryCycle,
                                salaryCycleDay: state?.salaryCycleDay,
                                openingBalance: state?.openingBalance,
                                baseRate: state?.baseRate,
                                baseRateType: state?.baseRateType,
                                freelancer: state?.freelancer,
                                isTrainingCompleted:
                                    trainingCompleted === 'true',
                            },
                        };
                        if (!!isEnabledXUtil) {
                            payload = {
                                ...payload,
                                value: {
                                    ...payload.value,
                                    staffCustCode: state?.staffCustCode,
                                    vendorId: state?.vendorId,
                                    customerId: state?.customerId,
                                    shiftTimingIds,
                                    joiningDateEpochMillis: moment(
                                        state?.joiningDateEpochMillis,
                                    ).valueOf(),
                                },
                            };
                        }
                        dispatchEditStaff(
                            payload,
                            async () => {
                                // console.log('Edit Staff SUCCESS');
                                await toggleloading(false);
                                handleClose();
                            },
                            async () => {
                                await toggleloading(false);
                                // console.log('Edit Staff FAILED');
                            },
                        );
                    } else {
                        let payload = {
                            name: state?.name,
                            contactNumber: state?.contactNumber,
                            vehicleTypeId: state?.vehicleTypeId,
                            vehicleNumber: state?.vehicleNumber,
                            hubId: state?.hubId,
                            selfieEnabled: state?.selfieEnabled,
                            // geofenceEnabled: state?.geofenceEnabled,
                            trackAndTraceEnabled: state?.trackAndTraceEnabled,
                            geofenceAutoApproval: state?.geofenceAutoApproval,
                            // companyId: state?.companyId,
                            // joiningDateEpochMillis: moment(
                            //     state?.joiningDateEpochMillis,
                            // ).valueOf(),
                            dateOfBirthEpochMillis: moment(
                                state?.dateOfBirthEpochMillis,
                            ).valueOf(),
                            staffType: state?.staffTypeId,
                            salaryType: state?.salaryType,
                            salaryCycle: state?.salaryCycle,
                            salaryCycleDay: state?.salaryCycleDay,
                            openingBalance: state?.openingBalance,
                            baseRate: state?.baseRate,
                            baseRateType: state?.baseRateType,
                            freelancer: state?.freelancer,
                        };
                        if (!!isEnabledXUtil) {
                            payload = {
                                ...payload,
                                staffCustCode: state?.staffCustCode,
                                vendorId: state?.vendorId,
                                customerId: state?.customerId,
                                shiftTimingIds,
                            };
                        }
                        dispatchCreateStaff(
                            payload,
                            async () => {
                                // console.log('Add Staff SUCCESS');
                                await toggleloading(false);
                                handleClose();
                            },
                            async () => {
                                await toggleloading(false);
                                // console.log('Add Staff FAILED');
                            },
                        );
                    }
                });
            }}
        >
            {isEdit ? 'Edit Staff' : 'Add Staff'}
        </Button>,
    ];
    useEffect(() => {
        moment?.weekdays()?.map((day, index) => {
            weekList?.push({
                id: index,
                name: day,
                value: day,
            });
        });
        let weekDays = [];
        let days = Array.from({ length: 28 }, (_, i) => i + 1);
        // console.log('Days', days);
        days?.map((day, index) => {
            weekDays?.push({
                id: index,
                name: day,
                value: day,
            });
        });
        setWeekList(weekList);
        setWeekDaysList(weekDays);
        toggleloading(true);
        dispatchGetHubList(
            orgId,
            (resp) => {
                !isEdit && toggleloading(false);
            },
            (resp) => {
                !isEdit && toggleloading(false);
            },
        );
        dispatchGetSalaryTypeList(
            (resp) => {
                !isEdit && toggleloading(false);
                if (checkHttpStatus(resp.status)) {
                    setSalaryTypeList(resp?.response?.types);
                }
            },
            (resp) => {
                !isEdit && toggleloading(false);
            },
        );
        dispatchGetStaffTypeList(
            (resp) => {
                !isEdit && toggleloading(false);
            },
            (resp) => {
                !isEdit && toggleloading(false);
            },
        );
        dispatchGetBaseRateTypeList(
            (resp) => {
                !isEdit && toggleloading(false);
                if (checkHttpStatus(resp.status)) {
                    // console.log('ZZZZZZZ', resp);
                    setBaseRateTypesList(resp?.response?.types);
                    switch (state?.salaryType) {
                        case 1:
                            setFilteredBaseRateTypesList(
                                resp?.response?.types?.filter((baseRate) =>
                                    baseRate?.name
                                        ?.toLowerCase()
                                        ?.includes('month'),
                                ),
                            );
                            break;
                        case 2:
                            setFilteredBaseRateTypesList(
                                resp?.response?.types?.filter((baseRate) =>
                                    baseRate?.name
                                        ?.toLowerCase()
                                        ?.includes('day'),
                                ),
                            );
                            break;
                        case 3:
                            setFilteredBaseRateTypesList(
                                resp?.response?.types?.filter((baseRate) =>
                                    baseRate?.name
                                        ?.toLowerCase()
                                        ?.includes('hour'),
                                ),
                            );
                            break;
                        case 4:
                            setFilteredBaseRateTypesList(
                                resp?.response?.types?.filter(
                                    (baseRate) =>
                                        !(
                                            baseRate?.name
                                                ?.toLowerCase()
                                                ?.includes('month') ||
                                            baseRate?.name
                                                ?.toLowerCase()
                                                ?.includes('day') ||
                                            baseRate?.name
                                                ?.toLowerCase()
                                                ?.includes('hour')
                                        ),
                                ),
                            );
                            break;
                        default:
                            setFilteredBaseRateTypesList(baseRateTypesList);
                            break;
                    }
                }
            },
            () => {},
        );
        dispatchGetRateCard(
            rateCardPayload,
            (resp) => {
                // console.log('RateCardResponse', resp);
                toggleloading(false);
                setRateCardResponse(resp?.response);
            },
            () => {
                toggleloading(false);
            },
        );
        dispatchFetchVehicleTypes(
            (resp) => {
                // console.log('Vehicle Types', resp?.response);
                setVehicleTypesList(resp?.response?.vehicleTypes);
            },
            (err) => {
                // console.log('Vehicle Types Error', err);
                setVehicleTypesList([]);
            },
        );
        if (isEdit) {
            // console.log('data', data);
            // setState(data);
            toggleloading(true);
            dispatchGetSingleStaff(
                data.id,
                (resp) => {
                    if (checkHttpStatus(resp?.status)) {
                        setState({
                            ...resp?.response,
                            // joiningDateEpochMillis:
                            //     resp?.response?.joiningDateEpochMillis > 0
                            //         ? resp?.response?.joiningDateEpochMillis
                            //         : null,

                            dateOfBirthEpochMillis:
                                resp?.response?.dateOfBirthEpochMillis > 0
                                    ? resp?.response?.dateOfBirthEpochMillis
                                    : null,
                            salaryType:
                                resp?.response?.salaryTypeId > 0
                                    ? resp?.response?.salaryTypeId
                                    : '',
                        });
                        if (!!isEnabledXUtil) {
                            let hubShiftMapping =
                                resp?.response?.hubShiftMappings;
                            if (!!hubShiftMapping?.length) {
                                setWorkTabHubNShiftList(
                                    hubShiftMapping?.map((hub) => ({
                                        hubId: hub.hubId,
                                    })),
                                );
                                hubShiftMapping?.forEach(async (hub, idx) => {
                                    const shiftIds = hub?.shiftLineItems;
                                    getShiftfromHubId(
                                        hub?.hubId,
                                        idx,
                                        shiftIds,
                                    );
                                });
                            }
                        }
                        // console.log('getSingleSTaff SUCCESS');
                        toggleloading(false);
                    }
                },
                () => {
                    // console.log('getSingleSTaff FAILED');
                    toggleloading(false);
                },
            );
        }
    }, []);

    useEffect(() => {
        let newRecord = workTabHubNShiftList?.map((hub) => {
            if (!!shiftsWithHub?.[hub?.hubId]) {
                return {
                    ...hub,
                    ...shiftsWithHub?.[hub?.hubId],
                };
            }
            return {
                ...hub,
            };
        });
        setWorkTabHubNShiftList(newRecord);
    }, [shiftsWithHub]);
    useEffect(() => {
        setRateCardPayload((prev) => {
            return {
                ...prev,
                hubId: state?.hubId,
            };
        });
    }, [state?.hubId]);

    useEffect(() => {
        // setState((prev) => {
        //     return {
        //         ...prev,
        //         salaryCycleDay: '',
        //     };
        // });
        if (
            state?.salaryCycle == 'Weekly' ||
            state?.salaryCycle == 'Bi-Weekly'
        ) {
            setSalaryCycleDayList(weekList);
        } else if (state?.salaryCycle == 'Monthly') {
            setSalaryCycleDayList(weekDaysList);
        }
    }, [state?.salaryCycle]);

    useEffect(() => {
        if (
            !salaryCycleDayList?.filter(
                (item) => item?.value == state?.salaryCycleDay,
            )?.length
        ) {
            setState((prev) => {
                return {
                    ...prev,
                    salaryCycleDay: '',
                };
            });
        }
        console.log('GGG', salaryCycleDayList, state?.salaryCycleDay);
    }, [salaryCycleDayList]);

    useEffect(() => {
        // console.log('workTabHubNShiftList', workTabHubNShiftList);
        checkoverlapping();
    }, [workTabHubNShiftList]);

    useEffect(() => {
        // console.log('STATE', state);
        formValidator('vehicleNumber', state?.vehicleNumber);
        if (isEnabledXUtil) {
            setSubmitButtonDisabled(
                !state?.name ||
                    (!state?.contactNumber.match(/[1-9][0-9]{9}/) &&
                        state?.contactNumber.length !== 10) ||
                    !state.dateOfBirthEpochMillis ||
                    !state?.staffTypeId ||
                    (!state?.vehicleNumber && state?.trackAndTraceEnabled) ||
                    (state?.contactNumber?.length > 0 &&
                        stateStatus?.contactNumber?.status) ||
                    (state?.vehicleNumber?.length > 0 &&
                        (stateStatus?.vehicleNumber?.status ||
                            null == state?.vehicleTypeId ||
                            state?.vehicleTypeId < 1))
                    ? true
                    : false,
            );
        } else {
            let isValid =
                !state?.name ||
                (!state?.contactNumber.match(/[1-9][0-9]{9}/) &&
                    state?.contactNumber.length !== 10) ||
                !state.dateOfBirthEpochMillis ||
                !state?.hubId ||
                !state?.staffTypeId ||
                (!state?.vehicleNumber &&
                    state?.trackAndTraceEnabled &&
                    !!checkVehicleNumberRequired(state?.vehicleTypeId)) ||
                (state?.contactNumber?.length > 0 &&
                    stateStatus?.contactNumber?.status) ||
                (state?.vehicleNumber?.length > 0 &&
                    (!vehicleNumberRegex.test(state?.vehicleNumber) ||
                        stateStatus?.vehicleNumber?.status ||
                        null == state?.vehicleTypeId ||
                        state?.vehicleTypeId < 1))
                    ? true
                    : false;
            setSubmitButtonDisabled(isValid);
        }
    }, [state]);

    useEffect(() => {
        switch (state?.salaryType) {
            case 1:
                setFilteredBaseRateTypesList(
                    baseRateTypesList?.filter((baseRate) =>
                        baseRate?.name?.toLowerCase()?.includes('month'),
                    ),
                );
                break;
            case 2:
                setFilteredBaseRateTypesList(
                    baseRateTypesList?.filter((baseRate) =>
                        baseRate?.name?.toLowerCase()?.includes('day'),
                    ),
                );
                break;
            case 3:
                setFilteredBaseRateTypesList(
                    baseRateTypesList?.filter((baseRate) =>
                        baseRate?.name?.toLowerCase()?.includes('hour'),
                    ),
                );
                break;
            case 4:
                setFilteredBaseRateTypesList(
                    baseRateTypesList?.filter(
                        (baseRate) =>
                            !(
                                baseRate?.name
                                    ?.toLowerCase()
                                    ?.includes('month') ||
                                baseRate?.name
                                    ?.toLowerCase()
                                    ?.includes('day') ||
                                baseRate?.name?.toLowerCase()?.includes('hour')
                            ),
                    ),
                );
                break;
            default:
                setFilteredBaseRateTypesList(baseRateTypesList);
                break;
        }
    }, [state?.salaryType]);

    const hubField = (
        <CustomTextField
            label="Hub Name"
            select
            fullWidth={true}
            value={state?.hubId ?? ''}
            onChange={(e) => handleInputChange(e.target.value, 'hubId')}
            defaultValue=""
            style={{
                marginBottom: '16px',
                width: '50%',
                marginRight: 5,
            }}
            isRequired={true}
        >
            {staff?.hubs?.length ? (
                staff?.hubs.map((hub, index) => (
                    <StyledMenuItem key={index} value={hub.id}>
                        {hub.name}
                    </StyledMenuItem>
                ))
            ) : (
                <StyledMenuItem value={null}>No Data Found</StyledMenuItem>
            )}
        </CustomTextField>
    );
    const staffTypeField = (
        <CustomTextField
            label="Staff Type"
            select
            fullWidth={true}
            value={state?.staffTypeId ?? ''}
            onChange={(e) => handleInputChange(e.target.value, 'staffTypeId')}
            defaultValue=""
            style={{
                marginBottom: '16px',
                width: '50%',
                marginRight: 5,
            }}
            isRequired={true}
        >
            {staff?.types?.length ? (
                staff?.types.map((hub, index) => (
                    <StyledMenuItem key={index} value={hub.id}>
                        {hub.name}
                    </StyledMenuItem>
                ))
            ) : (
                <StyledMenuItem value={null}>No Data Found</StyledMenuItem>
            )}
        </CustomTextField>
    );
    const dateofjoinField = (
        <CustomDatePicker
            label="Date of Join"
            value={state?.joiningDateEpochMillis}
            onChange={(e) => handleInputChange(e, 'joiningDateEpochMillis')}
            showInput={true}
            InputProps={{
                endAdornment: (
                    <IconButton
                        style={{
                            padding: 0,
                        }}
                    >
                        <InsertInvitationIcon size="small" />
                    </IconButton>
                ),
            }}
            style={{ marginBottom: '16px', width: '50%', marginRight: 5 }}
            isRequired={true}
        />
    );
    let isSalaryInfoTabDisabled = !state?.hubId;
    if (!!isEnabledXUtil) {
        isSalaryInfoTabDisabled = false;
    }
    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={isEdit ? 'Edit Staff' : 'Add New Staff'}
            actions={actions}
            loading={isloading}
            containerStyle={{ minHeight: '550px', maxHeight: '550px' }}
            stickyActions={true}
            // maxWidth={'sm'}
        >
            <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                style={{
                    backgroundColor: '#f7f7ff',
                }}
            >
                <Tab
                    label={
                        <span
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: 16,
                                fontWeight: 'bold',
                                textTransform: 'none',
                            }}
                        >
                            Basic Info
                        </span>
                    }
                    style={{
                        minWidth: !!isEnabledXUtil ? '33%' : '50%',
                    }}
                />
                {!!isEnabledXUtil && (
                    <Tab
                        label={
                            <span
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                }}
                            >
                                Work Info
                            </span>
                        }
                        style={{
                            minWidth: '33%',
                        }}
                        // disabled={!state?.hubId}
                    />
                )}
                <Tab
                    label={
                        <span
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: 16,
                                fontWeight: 'bold',
                                textTransform: 'none',
                            }}
                        >
                            Salary Info
                        </span>
                    }
                    style={{
                        minWidth: !!isEnabledXUtil ? '33%' : '50%',
                    }}
                    disabled={isSalaryInfoTabDisabled}
                />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
                <CustomTextField
                    fullWidth={true}
                    label="Staff Name"
                    value={state?.name}
                    onChange={(e) => handleInputChange(e.target.value, 'name')}
                    error={stateStatus?.name?.status}
                    helperText={stateStatus?.name?.error}
                    onBlur={(e) => formValidator('name', e.target.value)}
                    style={{ marginBottom: '16px' }}
                    isRequired={true}
                />
                {!!isEnabledXUtil ? staffTypeField : hubField}

                <CustomTextField
                    fullWidth={true}
                    label="Phone Number"
                    value={state?.contactNumber}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'contactNumber')
                    }
                    onBlur={(e) =>
                        formValidator('contactNumber', e.target.value)
                    }
                    error={stateStatus?.contactNumber?.status}
                    helperText={stateStatus?.contactNumber?.error}
                    style={{
                        marginBottom: '16px',
                        width: '48%',
                    }}
                    isRequired={true}
                    disabled={!!isEdit && !isSuperAdmin()}
                />

                {!!isEnabledXUtil ? dateofjoinField : staffTypeField}
                <CustomDatePicker
                    label="Date of Birth"
                    value={
                        moment(state?.dateOfBirthEpochMillis)?.valueOf() > 0 &&
                        moment()?.diff(
                            moment(state?.dateOfBirthEpochMillis),
                            'years',
                        ) >= 18
                            ? state?.dateOfBirthEpochMillis
                            : null
                    }
                    onChange={(e) =>
                        handleInputChange(e, 'dateOfBirthEpochMillis')
                    }
                    allowAge18={false}
                    allowFutureDates={false}
                    showInput={true}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                style={{
                                    padding: 0,
                                }}
                            >
                                <InsertInvitationIcon size="small" />
                            </IconButton>
                        ),
                    }}
                    style={{ marginBottom: '16px', width: '48%' }}
                    isRequired={true}
                />
                <CustomTextField
                    label="Vehicle Type"
                    select
                    fullWidth={true}
                    value={state?.vehicleTypeId > 0 ? state?.vehicleTypeId : ''}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'vehicleTypeId')
                    }
                    defaultValue=""
                    style={{
                        marginBottom: '16px',
                        width: '50%',
                        marginRight: 5,
                    }}
                    isRequired={true}
                >
                    {vehicleTypesList?.length ? (
                        vehicleTypesList?.map((vehicle, index) => (
                            <StyledMenuItem key={index} value={vehicle?.id}>
                                {vehicle?.value}
                            </StyledMenuItem>
                        ))
                    ) : (
                        <StyledMenuItem value={null}>
                            No Data Found
                        </StyledMenuItem>
                    )}
                </CustomTextField>

                {state?.vehicleTypeId > 0 && (
                    <CustomTextField
                        fullWidth={true}
                        label="Vehicle Number"
                        value={state?.vehicleNumber}
                        onChange={(e) =>
                            handleInputChange(
                                e.target.value.toUpperCase(),
                                'vehicleNumber',
                            )
                        }
                        onBlur={(e) =>
                            formValidator('vehicleNumber', e.target.value)
                        }
                        error={stateStatus?.vehicleNumber?.status}
                        helperText={stateStatus?.vehicleNumber?.error}
                        style={{ marginBottom: '16px', width: '48%' }}
                        isRequired={checkVehicleNumberRequired(
                            state?.vehicleTypeId,
                        )}
                    />
                )}

                {!!isEdit ? (
                    <div>
                        <Divider style={{ marginBottom: '16px' }} />
                        <Box>
                            <Typography
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 800,
                                    marginBottom: '18px',
                                }}
                                variant="body1"
                            >
                                Training Status
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: '16px',
                                marginBottom: '16px',
                            }}
                        >
                            <RadioGroup
                                row
                                value={trainingCompleted.toString()}
                                onChange={(e) => {
                                    // // console.log(
                                    //     'setTrainingCompleted: ',
                                    //     e.target.value,
                                    // );
                                    setTrainingCompleted(e.target.value);
                                }}
                            >
                                <RadioButton2
                                    value={'false'}
                                    background={
                                        trainingCompleted === 'true'
                                            ? 'rgba(255, 255, 255, 1)'
                                            : 'rgba(67, 65, 158, 0.08)'
                                    }
                                    label={
                                        <Typography
                                            variant="span"
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color:
                                                    trainingCompleted === 'true'
                                                        ? 'rgba(20, 17, 45, 1)'
                                                        : 'rgba(67, 65, 158, 1)',
                                            }}
                                        >
                                            {'Pending'}
                                        </Typography>
                                    }
                                />
                                <RadioButton2
                                    value={'true'}
                                    background={
                                        trainingCompleted === 'true'
                                            ? 'rgba(67, 65, 158, 0.08)'
                                            : 'rgba(255, 255, 255, 1)'
                                    }
                                    label={
                                        <Typography
                                            variant="span"
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color:
                                                    trainingCompleted === 'true'
                                                        ? 'rgba(67, 65, 158, 1)'
                                                        : 'rgba(20, 17, 45, 1)',
                                            }}
                                        >
                                            {'Completed'}
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                        </Box>
                        <Divider style={{ marginBottom: '16px' }} />
                    </div>
                ) : (
                    <></>
                )}

                {/* <CustomTextField
                fullWidth={true}
                label="Company ID"
                value={state?.companyId}
                onChange={(e) => handleInputChange(e.target.value, 'companyId')}
                onBlur={(e) => formValidator('companyId', e.target.value)}
                error={stateStatus?.companyId?.status}
                helperText={stateStatus?.companyId?.error}
                style={{ marginBottom: '16px' }}
            /> */}

                <Box>
                    <Typography
                        style={{
                            fontSize: '18px',
                            fontWeight: 800,
                            marginBottom: '18px',
                        }}
                        variant="body1"
                    >
                        Attendance Setup
                    </Typography>
                    <Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: '16px',
                            }}
                        >
                            <Typography
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: '#14112d',
                                }}
                            >
                                <SelfieAttendanceIcon
                                    width={24}
                                    height={24}
                                    style={{
                                        marginRight: '8px',
                                    }}
                                />
                                Enable Selfie
                            </Typography>
                            <CustomSwitch
                                checked={state.selfieEnabled}
                                onChange={(e) =>
                                    handleInputChange(
                                        e.target.checked,
                                        'selfieEnabled',
                                    )
                                }
                                name="selfieEnabled"
                            />
                        </Box>
                        {/* <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '16px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                color: '#14112d',
                            }}
                        >
                            <GeofenceAttendanceIcon
                                width={24}
                                height={24}
                                style={{
                                    marginRight: '8px',
                                }}
                            />
                            Enable Hub Geofence
                        </div>
                        <CustomSwitch
                            checked={state.geofenceEnabled}
                            onChange={(e) =>
                                handleInputChange(
                                    e.target.checked,
                                    'geofenceEnabled',
                                )
                            }
                            name="geofenceEnabled"
                        />
                    </Box> */}
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: '16px',
                            }}
                        >
                            <Typography
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: '#14112d',
                                }}
                            >
                                <GeofenceAutoApprovalIcon
                                    width={24}
                                    height={24}
                                    style={{
                                        marginRight: '8px',
                                    }}
                                />
                                Enable Geofence Auto Approval
                            </Typography>
                            <CustomSwitch
                                checked={state.geofenceAutoApproval}
                                onChange={(e) =>
                                    handleInputChange(
                                        e.target.checked,
                                        'geofenceAutoApproval',
                                    )
                                }
                                name="geofenceAutoApproval"
                            />
                        </Box>
                    </Box>
                    <Divider />
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        <Typography
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: '18px',
                            }}
                        >
                            <TrackingEnabledIcon
                                width={24}
                                height={24}
                                style={{
                                    marginRight: '8px',
                                }}
                            />
                            Enable Track and Trace
                        </Typography>
                        <CustomSwitch
                            checked={state.trackAndTraceEnabled}
                            onChange={(e) =>
                                handleInputChange(
                                    e.target.checked,
                                    'trackAndTraceEnabled',
                                )
                            }
                            name="trackAndTraceEnabled"
                        />
                    </Box>
                </Box>
            </TabPanel>
            {!!isEnabledXUtil && (
                <TabPanel value={activeTab} index={1}>
                    <FormLabel
                        component="div"
                        style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            marginBottom: 16,
                        }}
                    >
                        Add Hubs & Shifts
                    </FormLabel>
                    {workTabHubNShiftList?.map((hub, index) => {
                        return !hub?.hubId ? (
                            <Box>
                                <CustomTextField
                                    label="Hub Name"
                                    select
                                    fullWidth={true}
                                    value={hub?.hubId ?? ''}
                                    onChange={async (e) => {
                                        let hubId = e.target.value;
                                        await setWorkTabHubNShiftList(
                                            workTabHubNShiftList.map(
                                                (i, indx) => {
                                                    let data =
                                                        indx === index
                                                            ? {
                                                                  ...i,
                                                                  hubId,
                                                              }
                                                            : { ...i };
                                                    return data;
                                                },
                                            ),
                                        );
                                        await getShiftfromHubId(hubId, index);
                                    }}
                                    defaultValue=""
                                    style={{
                                        marginBottom: '16px',
                                        width: '100%',
                                        marginRight: 5,
                                    }}
                                    displayEmpty={true}
                                >
                                    {staff?.hubs?.length &&
                                        staff?.hubs.map((hub, index) => (
                                            <StyledMenuItem
                                                key={index}
                                                value={hub.id}
                                                disabled={
                                                    !!workTabHubNShiftList?.find(
                                                        (i) =>
                                                            i?.hubId ===
                                                            hub?.id,
                                                    )
                                                }
                                            >
                                                {hub.name}
                                            </StyledMenuItem>
                                        ))}
                                </CustomTextField>
                            </Box>
                        ) : (
                            <Box style={{ marginBottom: 8 }}>
                                <Box
                                    style={{
                                        backgroundColor:
                                            HUB_TYPE_ENUM[
                                                staff?.hubs
                                                    ?.find(
                                                        (i) =>
                                                            hub?.hubId ===
                                                            i?.id,
                                                    )
                                                    ?.primaryOperation?.toUpperCase()
                                            ]?.bgColor,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '6px 12px',
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        border: 'solid 1px #f0e7e6',
                                        borderBottom: 'none',
                                    }}
                                >
                                    <Typography
                                        variant="inherit"
                                        style={{
                                            color: '#252525',
                                            fontSize: 14,
                                        }}
                                    >
                                        Hub Name:{' '}
                                        <Typography
                                            variant="inherit"
                                            style={{
                                                color: '#252525',
                                                fontWeight: 600,
                                                fontSize: 14,
                                            }}
                                        >
                                            {
                                                staff?.hubs?.find(
                                                    (i) => hub?.hubId === i?.id,
                                                )?.name
                                            }
                                        </Typography>
                                    </Typography>
                                    {workTabHubNShiftList?.length > 1 && (
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setWorkTabHubNShiftList(
                                                    workTabHubNShiftList.filter(
                                                        (_, inx) =>
                                                            inx !== index,
                                                    ),
                                                );
                                            }}
                                        >
                                            <DeleteIcon
                                                color="error"
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    )}
                                </Box>
                                <Box
                                    style={{
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                        border: 'solid 1px rgba(38, 50, 56, 0.16)',
                                        borderTop: 'none',
                                        width: '100%',
                                        // padding: '16px 12px',
                                    }}
                                    // onClick={(event) => {
                                    //     setAnchorEl(event.currentTarget);
                                    // }}
                                >
                                    {
                                        <Box
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <StyledAutocomplete
                                                multiple={true}
                                                disableCloseOnSelect={true}
                                                limitTags={2}
                                                key={index}
                                                options={hub?.shiftList ?? []}
                                                getOptionLabel={(option) =>
                                                    `${moment(
                                                        option.startTimeEpochMillis,
                                                    ).format('HH:mm')}-${moment(
                                                        option.endTimeEpochMillis,
                                                    ).format('HH:mm')}`
                                                }
                                                disableClearable
                                                filterSelectedOptions={false}
                                                value={hub?.shifts}
                                                onChange={(e, newValue) => {
                                                    setWorkTabHubNShiftList(
                                                        workTabHubNShiftList.map(
                                                            (i, indx) => {
                                                                const data =
                                                                    indx ===
                                                                    index
                                                                        ? {
                                                                              ...i,
                                                                              shifts: newValue,
                                                                          }
                                                                        : {
                                                                              ...i,
                                                                          };
                                                                return data;
                                                            },
                                                        ),
                                                    );
                                                    setShiftsWithHub({
                                                        ...shiftsWithHub,
                                                        [hub?.hubId]: {
                                                            ...shiftsWithHub?.[
                                                                hub?.hubId
                                                            ],
                                                            shifts: newValue,
                                                        },
                                                    });
                                                }}
                                                includeInputInList={true}
                                                getLimitTagsText={(more) => {
                                                    let border = 'none';
                                                    hub.shifts.map(
                                                        (shift, indx) => {
                                                            if (indx > 1) {
                                                                border =
                                                                    overlapShifts.includes(
                                                                        shift.id,
                                                                    )
                                                                        ? '1px solid red'
                                                                        : 'none';
                                                            }
                                                            return shift;
                                                        },
                                                    );
                                                    return (
                                                        <Chip
                                                            style={{ border }}
                                                            label={`+${more}`}
                                                        />
                                                    );
                                                }}
                                                renderTags={(values, props) => {
                                                    return values.map(
                                                        (shift) => {
                                                            return (
                                                                <Chip
                                                                    onDelete={() => {
                                                                        setWorkTabHubNShiftList(
                                                                            workTabHubNShiftList.map(
                                                                                (
                                                                                    i,
                                                                                    indx,
                                                                                ) => {
                                                                                    const data =
                                                                                        indx ===
                                                                                        index
                                                                                            ? {
                                                                                                  ...i,
                                                                                                  shifts: i.shifts.filter(
                                                                                                      (
                                                                                                          i,
                                                                                                      ) =>
                                                                                                          i?.id !==
                                                                                                          shift?.id,
                                                                                                  ),
                                                                                              }
                                                                                            : {
                                                                                                  ...i,
                                                                                              };
                                                                                    return data;
                                                                                },
                                                                            ),
                                                                        );
                                                                        setShiftsWithHub(
                                                                            {
                                                                                ...shiftsWithHub,
                                                                                [hub?.hubId]:
                                                                                    {
                                                                                        ...shiftsWithHub?.[
                                                                                            hub
                                                                                                ?.hubId
                                                                                        ],
                                                                                        shifts: hub.shifts.filter(
                                                                                            (
                                                                                                i,
                                                                                            ) =>
                                                                                                i?.id !==
                                                                                                shift?.id,
                                                                                        ),
                                                                                    },
                                                                            },
                                                                        );
                                                                    }}
                                                                    style={{
                                                                        marginRight: 4,
                                                                        border: overlapShifts.includes(
                                                                            shift.id,
                                                                        )
                                                                            ? '1px solid red'
                                                                            : 'none',
                                                                    }}
                                                                    label={`${moment(
                                                                        shift?.startTimeEpochMillis,
                                                                    ).format(
                                                                        'HH:mm',
                                                                    )}-${moment(
                                                                        shift?.endTimeEpochMillis,
                                                                    ).format(
                                                                        'HH:mm',
                                                                    )}`}
                                                                />
                                                            );
                                                        },
                                                    );
                                                }}
                                                renderOption={(
                                                    option,
                                                    { selected },
                                                ) => (
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                            width: '100%',
                                                            borderBottom:
                                                                '1px solid #EBEBEB',
                                                        }}
                                                    >
                                                        <Box>
                                                            <Typography
                                                                style={{
                                                                    fontSize:
                                                                        '12px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {option.name}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize:
                                                                        '10px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {moment(
                                                                    option.startTimeEpochMillis,
                                                                ).format(
                                                                    'HH:mm',
                                                                )}{' '}
                                                                -{' '}
                                                                {moment(
                                                                    option.endTimeEpochMillis,
                                                                ).format(
                                                                    'HH:mm',
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                        <Checkbox
                                                            icon={icon}
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            color={'primary'}
                                                            checked={selected}
                                                        />
                                                    </Box>
                                                )}
                                                style={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <CustomTextField
                                                        {...params}
                                                        id={hub?.name}
                                                        name={hub?.name}
                                                        placeholder={
                                                            hub?.shifts?.length
                                                                ? ''
                                                                : 'Select Shift'
                                                        }
                                                        fullWidth={true}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        );
                    })}
                    <Button
                        key={1}
                        variant="outlined"
                        className={`cancelButton`}
                        onClick={() => {
                            setWorkTabHubNShiftList([
                                ...workTabHubNShiftList,
                                {},
                            ]);
                        }}
                        style={{
                            marginTop: 0,
                            marginBottom: 24,
                            borderColor: '#43419e',
                            backgroundColor: '#f0f0ff',
                        }}
                        disableElevation
                        fullWidth
                        startIcon={<PlusIcon />}
                    >
                        {'Add More Hubs'}
                    </Button>

                    <FormLabel
                        component="div"
                        style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            marginBottom: 16,
                        }}
                    >
                        Other Info
                    </FormLabel>
                    <CustomTextField
                        label="Client Name"
                        select
                        fullWidth={true}
                        value={state?.customerId ?? ''}
                        onChange={(e) =>
                            handleInputChange(e.target.value, 'customerId')
                        }
                        defaultValue=""
                        style={{
                            marginBottom: '16px',
                            width: '100%',
                            marginRight: 5,
                        }}
                    >
                        {clientList?.length == 0 && (
                            <StyledMenuItem value={null}>
                                No Data Found
                            </StyledMenuItem>
                        )}
                        {clientList?.map((cust, index) => (
                            <StyledMenuItem
                                key={index}
                                value={cust?.customerId}
                            >
                                {cust?.name}
                            </StyledMenuItem>
                        ))}
                    </CustomTextField>
                    <CustomTextField
                        fullWidth={true}
                        label="Staff Reference Number"
                        value={state?.staffCustCode}
                        onChange={(e) =>
                            handleInputChange(e.target.value, 'staffCustCode')
                        }
                        error={stateStatus?.staffCustCode?.status}
                        helperText={stateStatus?.staffCustCode?.error}
                        style={{ marginBottom: '16px' }}
                    />
                    <CustomTextField
                        label="Vendor"
                        select
                        fullWidth={true}
                        value={state?.vendorId ?? ''}
                        onChange={(e) =>
                            handleInputChange(e.target.value, 'vendorId')
                        }
                        defaultValue=""
                        style={{
                            marginBottom: '16px',
                            width: '100%',
                            marginRight: 5,
                        }}
                    >
                        {vendorList?.length ? (
                            vendorList?.map((vendor, index) => (
                                <StyledMenuItem
                                    key={index}
                                    value={vendor?.vendorId}
                                >
                                    {vendor?.name}
                                </StyledMenuItem>
                            ))
                        ) : (
                            <StyledMenuItem value={null}>
                                No Data Found
                            </StyledMenuItem>
                        )}
                    </CustomTextField>
                </TabPanel>
            )}
            <TabPanel value={activeTab} index={!!isEnabledXUtil ? 2 : 1}>
                <FormControl component="div" style={{ marginBottom: 16 }}>
                    <FormLabel
                        component="div"
                        style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                        }}
                        required={true}
                    >
                        Salary Type
                    </FormLabel>
                    <RadioGroup
                        aria-label="position"
                        name="position"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 12,
                        }}
                        value={state?.salaryType ?? 4}
                        onChange={(e) => {
                            // console.log(
                            //     'eeeeeee',
                            //     parseInt(e.target.value),
                            //     typeof e.target.value,
                            //     salaryTypeList[0]?.id,
                            //     typeof salaryTypeList[0]?.id,
                            // );
                            handleInputChange(
                                parseInt(e.target.value),
                                'salaryType',
                            );
                        }}
                    >
                        {salaryTypeList?.map((item, i) => (
                            <RadioButton
                                value={item?.id}
                                control={<Radio color="primary" />}
                                label={
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 8,
                                            paddingLeft: 0,
                                        }}
                                    >
                                        <Typography
                                            variant="span"
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {item?.name}
                                        </Typography>
                                        <Typography
                                            variant="span"
                                            style={{
                                                fontSize: 10,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {item?.description}
                                        </Typography>
                                    </Box>
                                }
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <FormLabel
                    component="div"
                    style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                    }}
                >
                    Salary Cycle
                </FormLabel>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <CustomTextField
                        label="Cycle"
                        select
                        fullWidth={true}
                        value={state?.salaryCycle ?? ''}
                        onChange={(e) =>
                            handleInputChange(e.target.value, 'salaryCycle')
                        }
                        defaultValue=""
                        style={{ margin: '10px 12px 12px 0' }}
                    >
                        {salaryCycleList?.length ? (
                            salaryCycleList?.map((type, index) => (
                                <StyledMenuItem key={index} value={type.value}>
                                    {type.name}
                                </StyledMenuItem>
                            ))
                        ) : (
                            <StyledMenuItem value={null}>
                                No Data Found
                            </StyledMenuItem>
                        )}
                    </CustomTextField>
                    <CustomTextField
                        label="Day"
                        select
                        fullWidth={true}
                        value={state?.salaryCycleDay ?? ''}
                        onChange={(e) =>
                            handleInputChange(e.target.value, 'salaryCycleDay')
                        }
                        defaultValue=""
                        style={{ margin: '10px 0 12px 0' }}
                    >
                        {salaryCycleDayList?.length ? (
                            salaryCycleDayList.map((type, index) => (
                                <StyledMenuItem key={index} value={type.value}>
                                    {type.name}
                                </StyledMenuItem>
                            ))
                        ) : (
                            <StyledMenuItem value={null}>
                                No Data Found
                            </StyledMenuItem>
                        )}
                    </CustomTextField>
                </Box>
                <FormLabel
                    component="div"
                    style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                    }}
                >
                    Salary Amount
                </FormLabel>
                <CustomTextField
                    fullWidth={true}
                    label="Opening Balance"
                    value={state?.openingBalance}
                    onChange={(e) =>
                        handleInputChange(e.target.value, 'openingBalance')
                    }
                    onBlur={(e) =>
                        formValidator('openingBalance', e.target.value)
                    }
                    error={stateStatus?.openingBalance?.status}
                    helperText={stateStatus?.openingBalance?.error}
                    style={{ marginBottom: '10px', marginTop: 10 }}
                    disabled={isEdit}
                />
                {rateCardResponse?.rateCard &&
                Object.keys(rateCardResponse?.rateCard)?.length ? (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: 'rgba(201, 204, 215, 0.16)',
                            padding: '6px 14px',
                            borderRadius: 8,
                            marginBottom: 10,
                        }}
                    >
                        <Typography
                            variant="span"
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: '#263238',
                            }}
                        >
                            Custom Rate
                        </Typography>

                        <RateCardModal data={rateCardResponse} />
                    </Box>
                ) : (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CustomTextField
                            label="Base Rate Type"
                            select
                            fullWidth={true}
                            value={state?.baseRateType ?? ''}
                            onChange={(e) =>
                                handleInputChange(
                                    e.target.value,
                                    'baseRateType',
                                )
                            }
                            defaultValue=""
                            style={{ margin: '0 12px 12px 0' }}
                        >
                            {filteredBaseRateTypesList?.length ? (
                                filteredBaseRateTypesList.map((type, index) => (
                                    <StyledMenuItem key={index} value={type.id}>
                                        {type.name}
                                    </StyledMenuItem>
                                ))
                            ) : (
                                <StyledMenuItem value={null}>
                                    No Data Found
                                </StyledMenuItem>
                            )}
                        </CustomTextField>
                        <CustomTextField
                            fullWidth={true}
                            label="Base Rate"
                            value={state?.baseRate}
                            onChange={(e) =>
                                handleInputChange(e.target.value, 'baseRate')
                            }
                            onBlur={(e) =>
                                formValidator('baseRate', e.target.value)
                            }
                            error={stateStatus?.baseRate?.status}
                            helperText={stateStatus?.baseRate?.error}
                            style={{ margin: '0 0 12px 0' }}
                        />
                    </Box>
                )}

                <FormLabel
                    component="div"
                    style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                    }}
                >
                    Employment nature
                </FormLabel>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '16px',
                        marginTop: 10,
                    }}
                >
                    <Typography
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            color: '#14112d',
                        }}
                    >
                        <FreeLancerIcon
                            width={24}
                            height={24}
                            style={{
                                marginRight: '8px',
                            }}
                        />
                        FreeLancer
                    </Typography>
                    <CustomSwitch
                        checked={state?.freelancer}
                        onChange={(e) =>
                            handleInputChange(e.target.checked, 'freelancer')
                        }
                        name="freelancer"
                    />
                </Box>
            </TabPanel>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
    orgId: state?.settings?.basicDetails?.id,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateStaff: (data, onSuccess, onError) =>
        dispatch(createStaff(data, onSuccess, onError)),
    dispatchEditStaff: (data, onSuccess, onError) =>
        dispatch(editStaff(data, onSuccess, onError)),
    dispatchGetHubList: (id, onSuccess, onError) =>
        dispatch(getHubList(id, onSuccess, onError)),
    dispatchGetStaffTypeList: (onSuccess, onError) =>
        dispatch(getStaffTypeList(onSuccess, onError)),
    dispatchGetSalaryTypeList: (onSuccess, onError) =>
        dispatch(getSalaryTypeList(onSuccess, onError)),
    dispatchGetBaseRateTypeList: (onSuccess, onError) =>
        dispatch(getBaseRateTypeList(onSuccess, onError)),
    dispatchGetSingleStaff: (payload, onSuccess, onError) =>
        dispatch(getSingleStaff(payload, onSuccess, onError)),
    dispatchGetRateCard: (payload, onSuccess, onError) =>
        dispatch(getRateCard(payload, onSuccess, onError)),
    dispatchGetVehicleTypeStaticValues: (payload, onSuccess, onError) =>
        dispatch(getReasonMenuList(payload, onSuccess, onError)),
    dispatchFetchVehicleTypes: (onSuccess, onError) =>
        dispatch(fetchVehicleTypes(onSuccess, onError)),
    dispatchFetchVendorList: (onSuccess, onError) =>
        dispatch(fetchVendorList(onSuccess, onError)),
    dispatchFetchClientList: (onSuccess, onError) =>
        dispatch(fetchClientList(onSuccess, onError)),
    dispatchfetchShiftTimingList: (payload, onSuccess, onError) =>
        dispatch(fetchShiftfilterList(payload, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)((props) =>
    !!props?.isEnabledXUtil ? (
        <AddEditStaffXUtilModal {...props} />
    ) : (
        <AddEditStaffModal {...props} />
    ),
);

const RadioButton2 = withStyles({
    root: {
        borderRadius: '8px',
        marginRight: 24,
        '&$checked': {
            border: 'solid 1px #43419e',
        },
    },
})((props) => (
    <FormControlLabel control={<Radio color="primary" />} {...props} />
));

const RadioButton = withStyles({
    root: {
        width: '50%',
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        borderRadius: '8px',
        marginTop: 10,
        marginRight: 0,
        '&$checked': {
            border: 'solid 1px #43419e',
        },
    },
})((props) => (
    <FormControlLabel control={<Radio color="primary" />} {...props} />
));
