import * as constants from '../constants';

export const bulkLeadUpload = ({ ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/lead/bulk-upload',
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const bulkLeadUploadStatus = ({ ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/lead/hire/bulk-update',
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const fetchAllLeads = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/lead/all',
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.SET_ALL_LEAD_INFO,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const fetchLeadCounts = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/lead/all/count',
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const fetchAllLeadReport = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/lead/all/report',
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const updateLeadStatus = (leadId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/${leadId}/update-status`,
        method: 'PUT',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const fetchLeadEvents = (leadId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/${leadId}/events`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead events', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const fetchBasicLeadProfile = (leadId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/${leadId}/fullProfile`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const fetchBasicLeadkyc = (leadId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/${leadId}/kyc`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});

export const updateLeadBasicInfo = (leadId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/${leadId}/saveFullProfile`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
export const updateLeadkycInfo = (leadId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/${leadId}/saveKyc`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('lead list', response);
            return {
                type: constants.DO_NOTHING,
                payload: response.response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    },
});
